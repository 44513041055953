import React, { useState } from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import { NavLink } from "react-router-dom";
import useRegisterForm from "./useAuthForm/useRegisterForm";
import ErrorMessage from "../../common/ErrorMessage";
import DatePicker from "react-date-picker";
import { BiCalendar } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import GoogleInput from "../../common/GoogleInput";
import { useShowPassword } from "../../../utils/utils";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export const RegisterForm: React.FC = () => {
	const { register, onSubmit, errors, setValue, isLoading } = useRegisterForm();
	const [bornDate, setBornDate] = useState<Date>();
	const [phone, setPhone] = useState<string>();
	const [phoneWhatshap, setPhoneWhatshap] = useState<string>();
	const [address, setAddress] = useState<string>("");
	const [haveMinUppercase, setHaveMinUppercase] = useState(false);
	const [haveMinLowercase, setHaveMinLowercase] = useState(false);
	const [haveMinNumber, setHaveMinNumber] = useState(false);
	const [haveMinCharacter, setHaveMinCharacter] = useState(false);
	const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);
	const { isShowPassword, toggleShowPassword } = useShowPassword();
	const { isShowPassword: isShowPass, toggleShowPassword: toggleShowPass } = useShowPassword();

	const countUppercase = (str) => {
		if (str.replace(/[^A-Z]/g, "").length >= 1) {
			return true;
		}
		return false;
	};

	const countLowercase = (str) => {
		if (str.replace(/[^a-z]/g, "").length >= 1) {
			return true;
		}
		return false;
	};

	const countNumber = (str) => {
		if (str.replace(/[^0-9]/g, "").length >= 1) {
			return true;
		}
		return false;
	};

	const countCharacters = (str) => {
		if (str.length >= 8) {
			return true;
		}
		return false;
	};

	const countSpecial = (str) => {
		const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
		let count = 0;
		for (let i = 0; i < str.length; i++) {
			if (!punct.includes(str[i])) {
				continue;
			}
			count++;
		}
		// return count;
		if (count >= 1) {
			return true;
		}
		return false;
	};
	const handleChangeDate = (date) => {
		console.log(date);
		setBornDate(date);
		setValue("birthdate", date);
	};
	const passwordHandleChange = (e) => {
		var password = e.target.value;
		if (countUppercase(password)) {
			setHaveMinUppercase(true);
		} else {
			setHaveMinUppercase(false);
		}
		if (countLowercase(password)) {
			setHaveMinLowercase(true);
		} else {
			setHaveMinLowercase(false);
		}
		if (countNumber(password)) {
			setHaveMinNumber(true);
		} else {
			setHaveMinNumber(false);
		}
		if (countCharacters(password)) {
			setHaveMinCharacter(true);
		} else {
			setHaveMinCharacter(false);
		}
		if (countSpecial(password)) {
			setHaveMinSpecialCharacter(true);
		} else {
			setHaveMinSpecialCharacter(false);
		}

		setValue("password", e.target.value);
	};
	return (
		<form id="auth-form" onSubmit={onSubmit} data-testid="register-form">
			<div className="row auth-form-row">
				<div className="col-md-6 auth-input-col">
					<div className="form-group auth-form-group">
						<label htmlFor="nom" aria-labelledby="nom">
							Nom
						</label>
						<span className="text-danger">*</span>
						<input
							type="text"
							className="form-control auth-form-control"
							id="nom"
							{...register("lastname")}
							data-testid="lastnameId"
						/>
						{errors.lastname?.message && (
							<ErrorMessage message={errors.lastname.message} />
						)}
					</div>
				</div>
				<div className="col-md-6 auth-input-col">
					<div className="form-group auth-form-group">
						<label htmlFor="prenom" aria-labelledby="prenom">
							Prénom
						</label>
						<span className="text-danger">*</span>
						<input
							type="text"
							className="form-control auth-form-control"
							id="prenom"
							{...register("firstname")}
							data-testid="firstnameId"
						/>
						{errors.firstname?.message && (
							<ErrorMessage message={errors.firstname.message} />
						)}
					</div>
				</div>
				<div className="col-md-6 auth-input-col">
					<div className="form-group auth-form-group">
						<label htmlFor="civilite" aria-labelledby="civilite">
							Civilité
						</label>
						<select
							id="civilite"
							{...register("civilite")}
							data-testid="civiliteId"
							className="form-select"
							style={{
								backgroundColor: "#F3F3F3",
								borderColor: "#F3F3F3",
								padding: "15px",
								borderRadius: "5px",
							}}
						>
							<option value="homme">Homme</option>
							<option value="femme">Femme</option>
						</select>
						{errors.civilite?.message && (
							<ErrorMessage message={errors.civilite.message} />
						)}
					</div>
				</div>
				<div className="col-md-6 auth-input-col">
					<div className="form-group auth-form-group nice-dates-container">
						<label
							htmlFor="date_de_naissance"
							aria-labelledby="date_de_naissance"
						>
							Date de naisssance
						</label>
						<DatePicker
							className="form-control auth-form-control"
							value={bornDate}
							onChange={(date) => handleChangeDate(date)}
							name="date"
							maxDate={new Date()}
							calendarIcon={
								<BiCalendar
									style={{
										color: "#200E3280",
										fontSize: "24px",
										position: "absolute",
										top: "25%",
										right: "5",
									}}
								/>
							}
							clearIcon={<IoClose />}
						/>
						{errors.birthdate?.message && (
							<ErrorMessage message={errors.birthdate.message} />
						)}
					</div>
				</div>
				<div className="col-md-6 auth-input-col">
					<div className="form-group auth-form-group">
						<label htmlFor="adresse" aria-labelledby="adresse">
							Adresse
						</label>
						{/* <input
							type="text"
							className="form-control auth-form-control"
							id="adresse"
							{...register("adresse")}
							data-testid="adresseId"
						/> */}
						<GoogleInput
							value={address}
							onChange={(value) => {
								setValue("adresse", value?.rue);
								setAddress(value?.rue);
							}}
							className="form-control auth-form-control"
						/>
						{errors.adresse?.message && (
							<ErrorMessage message={errors.adresse.message} />
						)}
					</div>
				</div>
				{/* <div className="col-md-6 auth-input-col">
					<div className="form-group auth-form-group">
						<label htmlFor="code_postal" aria-labelledby="code_postal">
							Code postal
						</label>
						<input
							type="text"
							className="form-control auth-form-control"
							id="code_postal"
							{...register("postal_code")}
							data-testid="postalCodeId"
						/>
						{errors.postal_code?.message && (
							<ErrorMessage message={errors.postal_code.message} />
						)}
					</div>
				</div> */}
				<div className="col-md-6 auth-input-col">
					<div className="form-group auth-form-group">
						<label htmlFor="telephone" aria-labelledby="telephone">
							Numéro de téléphone
						</label>
						<span className="text-danger">*</span>
						{/* <input
							type="tel"
							className="form-control auth-form-control"
							id="telephone"
							{...register("phone")}
							data-testid="phoneId"
						/> */}
						<PhoneInput
							country={"fr"}
							value={phone}
							onChange={(phone) => {
								setPhone(phone);
								setValue("phone", phone);
							}}
							containerClass="form-control auth-form-control mb-3 p-0"
							inputClass="react-custom-tel-input"
						/>
						{errors.phone?.message && (
							<ErrorMessage message={errors.phone.message} />
						)}
					</div>
				</div>
				<div className="col-md-6 auth-input-col">
					<div className="form-group auth-form-group">
						<label
							htmlFor="numero-whatshapp"
							aria-labelledby="numero-whatshapp"
						>
							Numéro whatsapp
						</label>
						{/* <input
							type="tel"
							className="form-control auth-form-control"
							id="numero-whatshapp"
							{...register("whatsapp")}
							data-testid="whatshappNumerId"
						/> */}
						<PhoneInput
							country={"fr"}
							value={phoneWhatshap}
							onChange={(phone) => {
								setPhoneWhatshap(phone);
								setValue("whatsapp", phone);
							}}
							containerClass="form-control auth-form-control mb-3 p-0"
							inputClass="react-custom-tel-input"
						/>
						{errors.whatsapp?.message && (
							<ErrorMessage message={errors.whatsapp.message} />
						)}
					</div>
				</div>
				<div className="col-md-6 auth-input-col">
					<div className="form-group auth-form-group">
						<label htmlFor="email" aria-labelledby="email">
							Adresse Email
						</label>
						<span className="text-danger">*</span>
						<input
							type="email"
							className="form-control auth-form-control"
							id="email"
							{...register("email")}
							data-testid="emailId"
						/>
						{errors.email?.message && (
							<ErrorMessage message={errors.email.message} />
						)}
					</div>
				</div>
				<div className="col-md-6 auth-input-col">
					<div className="form-group auth-form-group">
						<label htmlFor="password" aria-labelledby="password">
							Mot de passe
						</label>
						<span className="text-danger">*</span>
						<div className="position-relative">
							<input
								type={`${isShowPassword ? "text" : "password"}`}
								className="form-control auth-form-control"
								id="password"
								// {...register("password")}
								data-testid="passwordId"
								onChange={passwordHandleChange}
							/>
							{errors.password?.message && (
								<ErrorMessage message={errors.password.message} />
							)}
							<span
								className="btn-toggle-password"
								role="button"
								onClick={toggleShowPassword}
							>
								{isShowPassword ? (
									<AiOutlineEyeInvisible />
								) : (
									<AiOutlineEye />
								)}
							</span>
						</div>
					</div>
				</div>
				<div className="col-md-6 auth-input-col">
					<div className="form-group auth-form-group">
						<label
							htmlFor="confirm-password"
							aria-labelledby="confirm-password"
						>
							Confimer mot de passe
						</label>
						<span className="text-danger">*</span>
						<div className="position-relative">
							<input
								type={`${isShowPass ? "text" : "password"}`}
								className="form-control auth-form-control"
								id="confirm-password"
								{...register("confirmPassword")}
								data-testid="confirmPasswordId"
							/>
							<span
								className="btn-toggle-password"
								role="button"
								onClick={toggleShowPass}
							>
								{isShowPass ? (
									<AiOutlineEyeInvisible />
								) : (
									<AiOutlineEye />
								)}
							</span>
						</div>
						{errors.confirmPassword?.message && (
							<ErrorMessage message={errors.confirmPassword.message} />
						)}
					</div>
				</div>
				<div className="col-md-6">
					<div className="input-group my-2 password-validation-terms-row">
						<div className="password-validation-terms-container">
							<h4 className="password-validation-terms-title">
								Votre mot de passe doit contenir :{" "}
							</h4>
							<ul className="password-validation-terms-ul p-l-14">
								<li
									className={
										"password-rule-item " +
										(haveMinCharacter && "text-success")
									}
								>
									Au minimum 8 caractères
								</li>
								<li
									className={
										"password-rule-item " +
										(haveMinUppercase && "text-success")
									}
								>
									Au minimum 1 caractère en majuscule
								</li>
								<li
									className={
										"password-rule-item " +
										(haveMinLowercase && "text-success")
									}
								>
									Au minimum 1 caractère en minuscule
								</li>
								<li
									className={
										"password-rule-item " +
										(haveMinNumber && "text-success")
									}
								>
									Au minimum 1 nombre
								</li>
								<li
									className={
										"password-rule-item " +
										(haveMinSpecialCharacter &&
											"text-success")
									}
								>
									Au minimum 1 caractère spéciale
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="col-md-12 auth-input-col form-check mb-4 ms-3">
					<label
						className="container-input-chexkbox form-check-label-costum"
						aria-labelledby="accept-terms"
					>
						<input
							type="checkbox"
							{...register("condition")}
							data-testid="acceptTerms"
						/>
						<span className="checkmark-input-chexkbox"></span>
						J’ai lu et j’accepte{" "}
						<span className="text-theme">
							les conditions générales d’utilisations
						</span>
					</label>
					{errors.condition?.message && (
						<ErrorMessage message={errors.condition.message} />
					)}
				</div>
				<div className="col-md-6 offset-md-3 auth-submit-btn-container">
					<button type="submit" className="btn btn-primary auth-submit-btn">
						{!isLoading ? "Inscrption" : "Chargement..."}
					</button>
				</div>
			</div>
		</form>
	);
};

const Register = () => {
	return (
    <div className="auth-component">
      <FrontHeader />
      <div className="fixed-after-header">
        <div className="auth-container">
          <div className="auth-row row">
            <div className="col-md-4 auth-col auth-left-side-col no-view-l-mobile">
              <div className="auth-left-side-container"></div>
            </div>
            <div className="col-md-8 auth-col auth-right-side-col">
              <div className="auth-register-right-side-container">
                <div className="auth-form-container">
                  <h1 className="auth-form-title">Créer un compte Munngano</h1>
                  <RegisterForm />
                  <div className="d-flex justify-content-end align-items-center mt-4">
                    <p className="text-inscription mb-0">
                      Vous avez déjà un compte?
                    </p>
                    <NavLink
                      to="/app/se-connecter"
                      className="btn-inscription-color ps-2"
                    >
                      Connectez-vous
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
