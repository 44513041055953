type ENV = "dev" | "prod" | "demo" | "app-prod";

export const currentEnv: ENV = "prod";

const env: ENV = currentEnv;

export const Env = env;

const API_DEV_URL = "https://api-munngano.withvolkeno.com/";
const API_DEMO_URL = "https://api.preprod.munnganoconnectt.com/";
const API_PROD_URL = "https://api.munnganoconnectt.com/";
const API_APP_PROD_URL = "https://api.munnganoconnectt.com/";

export const APP_URL_DEV = "https://munngano-app.withvolkeno.com";
export const APP_URL_DEMO = "https://preprod.munnganoconnectt.com";
export const APP_URL_PROD = "https://munnganoconnectt.com";
export const APP_URL_APP_PROD = "https://app.munnganoconnectt.com";

function processApiUrl() {
  if (env === "prod") return API_PROD_URL;
  if (env === "demo") return API_DEMO_URL;
  if (env === "app-prod") return API_APP_PROD_URL;
  return API_DEV_URL;
}

function processAppUrl() {
  if (env === "prod") return APP_URL_PROD;
  if (env === "demo") return APP_URL_DEMO;
  if (env === "app-prod") return APP_URL_APP_PROD;
  return APP_URL_DEV;
}
export const APP_URL = processAppUrl();
export const ApiBaseUrl = processApiUrl();

export const PAYPAL_CLIENT_ID =
  "ASs7cbQZWcI31NUvpWOdUMXS7i4bxNFHjrh3F9pvhUt4RTFONjd48t9MNJ17vSyrn97ArhyLS-tYNMnb";

export const RECAPTCHA_SITE_KEY = "6LeFv1wjAAAAAG046cXGUT94NXHH-GG5h117_eQW";

export const INTECH_API_KEY = "8F7BC0F0-A664-4BFC-9BA1-162BAB9A8BE1";

export const CALLBACK_URL = `${ApiBaseUrl}api/intech/callback/`;
